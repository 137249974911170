var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('BaseModalForm', {
    attrs: {
      "headline": _vm.headline,
      "modalType": _vm.modalType,
      "editable": ""
    },
    on: {
      "submit": _vm.handleSubmit,
      "close": function close($event) {
        return _vm.$emit('close');
      }
    }
  }, [_c('v-form', {
    ref: "connectForm",
    attrs: {
      "lazy-validation": ""
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "md": "6"
    }
  }, [_c('v-autocomplete', {
    attrs: {
      "label": "Kategori",
      "items": _vm.teachingMaterialCategories,
      "item-text": "name",
      "readonly": _vm.readonly,
      "item-value": "name",
      "rules": [function (v) {
        return !!v || 'Fyll inn kategori';
      }],
      "required": ""
    },
    on: {
      "change": _vm.categoryChanged
    },
    model: {
      value: _vm.categoryModel.category,
      callback: function callback($$v) {
        _vm.$set(_vm.categoryModel, "category", $$v);
      },
      expression: "categoryModel.category"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "md": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Navn",
      "rules": [function (v) {
        return !!v || 'Fyll inn navn';
      }],
      "required": "",
      "readonly": _vm.readonly
    },
    model: {
      value: _vm.categoryModel.name,
      callback: function callback($$v) {
        _vm.$set(_vm.categoryModel, "name", $$v);
      },
      expression: "categoryModel.name"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-textarea', {
    attrs: {
      "readonly": _vm.readonly,
      "label": "Beskrivelse"
    },
    model: {
      value: _vm.categoryModel.category.description,
      callback: function callback($$v) {
        _vm.$set(_vm.categoryModel.category, "description", $$v);
      },
      expression: "categoryModel.category.description"
    }
  })], 1), _vm.currentCategory ? _c('v-row', [_vm.currentCategory.attribute1 ? _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6",
      "sm": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "readonly": _vm.readonly,
      "label": _vm.currentCategory.attribute1,
      "required": ""
    },
    model: {
      value: _vm.categoryModel.attribute1,
      callback: function callback($$v) {
        _vm.$set(_vm.categoryModel, "attribute1", $$v);
      },
      expression: "categoryModel.attribute1"
    }
  })], 1) : _vm._e(), _vm.currentCategory.attribute2 ? _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6",
      "sm": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "readonly": _vm.readonly,
      "label": _vm.currentCategory.attribute2,
      "required": ""
    },
    model: {
      value: _vm.categoryModel.attribute2,
      callback: function callback($$v) {
        _vm.$set(_vm.categoryModel, "attribute2", $$v);
      },
      expression: "categoryModel.attribute2"
    }
  })], 1) : _vm._e(), _vm.currentCategory.attribute3 ? _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6",
      "sm": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "readonly": _vm.readonly,
      "label": _vm.currentCategory.attribute3,
      "required": ""
    },
    model: {
      value: _vm.categoryModel.attribute3,
      callback: function callback($$v) {
        _vm.$set(_vm.categoryModel, "attribute3", $$v);
      },
      expression: "categoryModel.attribute3"
    }
  })], 1) : _vm._e(), _vm.currentCategory.attribute4 ? _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6",
      "sm": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "readonly": _vm.readonly,
      "label": _vm.currentCategory.attribute4,
      "required": ""
    },
    model: {
      value: _vm.categoryModel.attribute4,
      callback: function callback($$v) {
        _vm.$set(_vm.categoryModel, "attribute4", $$v);
      },
      expression: "categoryModel.attribute4"
    }
  })], 1) : _vm._e(), _vm.currentCategory.attribute5 ? _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6",
      "sm": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "readonly": _vm.readonly,
      "label": _vm.currentCategory.attribute5,
      "required": ""
    },
    model: {
      value: _vm.categoryModel.attribute5,
      callback: function callback($$v) {
        _vm.$set(_vm.categoryModel, "attribute5", $$v);
      },
      expression: "categoryModel.attribute5"
    }
  })], 1) : _vm._e()], 1) : _vm._e(), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-switch', {
    attrs: {
      "readonly": _vm.readonly,
      "value": false,
      "inset": "",
      "rules": [function (v) {
        return /(?:true|false)/.test(v) || 'Velg aktiv / inaktiv';
      }],
      "label": "Aktiv"
    },
    model: {
      value: _vm.categoryModel.isActive,
      callback: function callback($$v) {
        _vm.$set(_vm.categoryModel, "isActive", $$v);
      },
      expression: "categoryModel.isActive"
    }
  })], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }