var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.modalData.showModal && _vm.teachingMaterialCategories ? _c('BaseModal', {
    on: {
      "close": function close($event) {
        _vm.modalData.showModal = false;
      }
    }
  }, [_c('TeachingMaterialModal', {
    attrs: {
      "headline": _vm.modalData.modalHeadline,
      "modalType": _vm.modalData.modalType,
      "teachingMaterialCategories": _vm.teachingMaterialCategories,
      "existingItemModal": _vm.modalData.existingItem,
      "elementId": _vm.modalData.existingItemId
    },
    on: {
      "close": function close($event) {
        _vm.modalData.showModal = false;
      }
    }
  })], 1) : _vm._e(), _c('BaseLayout', {
    staticClass: "px-0",
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('span', {
          attrs: {
            "data-cy": "titleLæremidler"
          }
        }, [_vm._v(" Læremidler ")])];
      },
      proxy: true
    }, {
      key: "actions",
      fn: function fn() {
        return [_c('v-text-field', {
          staticClass: "mr-5",
          attrs: {
            "append-icon": "mdi-magnify",
            "label": "Søk",
            "single-line": "",
            "hide-details": ""
          },
          model: {
            value: _vm.search,
            callback: function callback($$v) {
              _vm.search = $$v;
            },
            expression: "search"
          }
        }), _c('v-btn', {
          attrs: {
            "color": "white",
            "outlined": "",
            "data-cy": "openNewTeachingmaterial"
          },
          on: {
            "click": _vm.openNewTeachingmaterial
          }
        }, [_c('v-icon', {
          attrs: {
            "left": ""
          }
        }, [_vm._v("mdi-plus")]), _vm._v(" Nytt læremiddel ")], 1)];
      },
      proxy: true
    }])
  }, [[_c('BaseTableFiltered', {
    staticClass: "mt-3",
    attrs: {
      "headers": _vm.headers,
      "items": _vm.teachingMaterials,
      "filter": _vm.filter,
      "search": _vm.search
    },
    scopedSlots: _vm._u([{
      key: "item.isActive",
      fn: function fn(_ref) {
        var value = _ref.value;
        return [_vm._v(" " + _vm._s(value ? "Aktiv" : "Inaktiv") + " ")];
      }
    }, {
      key: "actions",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref3) {
              var on = _ref3.on,
                  attrs = _ref3.attrs;
              return [_c('v-icon', _vm._g(_vm._b({
                staticClass: "mr-2",
                on: {
                  "click": function click($event) {
                    return _vm.openDisplayTeachingmaterial(item, item.name);
                  }
                }
              }, 'v-icon', attrs, false), on), [_vm._v(" mdi-open-in-new ")])];
            }
          }], null, true)
        }, [_c('span', [_vm._v("Vis " + _vm._s(item.name))])]), _c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref4) {
              var on = _ref4.on,
                  attrs = _ref4.attrs;
              return [_c('v-icon', _vm._g(_vm._b({
                staticClass: "mr-2",
                on: {
                  "click": function click($event) {
                    return _vm.openEditTeachingmaterial(item, item.name);
                  }
                }
              }, 'v-icon', attrs, false), on), [_vm._v(" mdi-pencil ")])];
            }
          }], null, true)
        }, [_c('span', [_vm._v("Rediger " + _vm._s(item.name))])])];
      }
    }], null, true)
  })]], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }